export function socialMediaButtons() {
  document.addEventListener('DOMContentLoaded', function () {
    const icons = document.querySelectorAll('.social-icon');

    icons.forEach((icon) => {
      const src = icon.getAttribute('data-src');
      fetch(src)
        .then((response) => response.text())
        .then((data) => {
          const parser = new DOMParser();
          const svg = parser
            .parseFromString(data, 'image/svg+xml')
            .querySelector('svg');

          if (svg) {
            icon.appendChild(svg);
          }
        })
        .catch((error) => console.error('Error fetching SVG:', error));
    });
  });
}
