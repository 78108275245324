(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/opt/bitnami/apache/htdocs/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/opt/bitnami/apache/htdocs/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/opt/bitnami/apache/htdocs/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/ui/src/index.js";


import index_4App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-widget/ui/src/index.js";


import index_5App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-text-field-widget/ui/src/index.js";


import index_6App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-textarea-field-widget/ui/src/index.js";


import index_7App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-select-field-widget/ui/src/index.js";


import index_8App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-radio-field-widget/ui/src/index.js";


import index_9App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-file-field-widget/ui/src/index.js";


import index_10App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-checkboxes-field-widget/ui/src/index.js";


import index_11App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-boolean-field-widget/ui/src/index.js";


import index_12App from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-conditional-widget/ui/src/index.js";


import index_13App from "/opt/bitnami/apache/htdocs/modules/asset/ui/src/index.js";


import index_14App from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/accordion-widget/ui/src/index.js";


import index_15App from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/image-gallery-widget/ui/src/index.js";


import index_16App from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/map-widget/ui/src/index.js";



import index_0Stylesheet from "/opt/bitnami/apache/htdocs/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_1Stylesheet from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/ui/src/index.scss";


import index_2Stylesheet from "/opt/bitnami/apache/htdocs/node_modules/@apostrophecms/form/modules/@apostrophecms/form-widget/ui/src/index.scss";


import index_3Stylesheet from "/opt/bitnami/apache/htdocs/modules/asset/ui/src/index.scss";


import index_4Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/accordion-widget/ui/src/index.scss";


import index_5Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/button-widget/ui/src/index.scss";


import index_6Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/button-strip-widget/ui/src/index.scss";


import index_7Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/call-to-action-widget/ui/src/index.scss";


import index_8Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/columns-widget/ui/src/index.scss";


import index_9Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/columns-with-header-widget/ui/src/index.scss";


import index_10Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/custom-form-widget/ui/src/index.scss";


import index_11Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/image-gallery-widget/ui/src/index.scss";


import index_12Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/map-widget/ui/src/index.scss";


import index_13Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/small-call-to-action-widget/ui/src/index.scss";


import index_14Stylesheet from "/opt/bitnami/apache/htdocs/modules/content-widget-modules/side-by-side-widget/ui/src/index.scss";


import index_15Stylesheet from "/opt/bitnami/apache/htdocs/modules/pieces-modules/team-member-widget/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();
index_13App();
index_14App();
index_15App();
index_16App();